import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip } from '@progress/kendo-react-charts'
import HazardClassCase, { Duty } from 'types/HazardClassCase'
import CenteredContent from 'views/Common/Layout/CenteredContent'
import classes from './PatternCharts.module.css'
import {
    ChartTitleCustom,
    PieChartColorGreen,
    PieChartColorOrange,
    PieChartColorRed,
    PieChartColorYellow,
} from './PatternsPieChart'

const pieChartDiameter = 190
const PatternCharts = (props: { case: HazardClassCase }) => {
    // in case of multiple operating dates, we want to count the duty multiple times
    const matchingDutiesCount = props.case.duties.reduce((sum: number, duty: Duty) => {
        return sum + duty.operatingDatesCount
    }, 0)

    return (
        <div style={{ width: '200px' }}>
            <CenteredContent>{matchingDutiesCount} Matching Duties</CenteredContent>
            <CenteredContent>{props.case.truePositive.lt80} Hazard Duties</CenteredContent>
            <div style={{ display: 'flex', width: pieChartDiameter, height: pieChartDiameter }}>
                <DonutChart diameter={pieChartDiameter} pattern={props.case} />
            </div>
            <SummaryTable pattern={props.case} />
        </div>
    )
}

const renderDonutTooltip = (context: any) => {
    const { series, value } = context.point || context
    return (
        <div>
            Accounts for {Math.round(value)}% of All Duties w Score {series.name}
        </div>
    )
}

const generatePPVSeriesObject = (thresholdName: string, matchingValue: number, color: string) => {
    return {
        name: thresholdName,
        data: [
            {
                category: 'Matching',
                value: matchingValue,
                color,
            },
            {
                category: 'Non-Matching',
                value: 100 - matchingValue,
                color: '#ddd',
            },
        ],
    }
}

const DonutChart = ({
    diameter,
    pattern,
    className,
}: {
    diameter: number
    pattern: HazardClassCase
    className?: string
}) => {
    // todo: as discussed with Ben, switch to bins (except outer, >80, <70).  Needs properties on the c# side.
    const seriesData = [
        generatePPVSeriesObject('< 80', pattern.positivePredictedValue.lt80, PieChartColorYellow),
        generatePPVSeriesObject('< 75', pattern.positivePredictedValue.lt75, PieChartColorOrange),
        generatePPVSeriesObject('< 70', pattern.positivePredictedValue.lt70, PieChartColorRed),
    ]

    const mapSeries = (series: any, index: number) => (
        <ChartSeriesItem
            key={index.toString()}
            type="donut"
            startAngle={90}
            name={series.name}
            data={series.data}
            field="value"
            categoryField="category"
            colorField="color"
            padding={0}
        />
    )

    return (
        <Chart transitions={false} className={className}>
            <ChartTitleCustom titleText="Matching % of Hazard Duties (PPV)" />
            <ChartTooltip render={renderDonutTooltip} />
            <ChartLegend visible={false} />
            <ChartArea background="none" height={diameter} width={diameter} />
            <ChartSeries>{seriesData.map(mapSeries)}</ChartSeries>
        </Chart>
    )
}

const SummaryTable = ({ pattern }: { pattern: HazardClassCase }) => {
    return (
        <table className={classes.summaryTable}>
            <thead>
                <tr>
                    <th>Threshold</th>
                    <th>Duties</th>
                    <th>PPV (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ color: 'white', backgroundColor: PieChartColorRed }}>&lt; 70</td>
                    <td>{pattern.truePositive.lt70}</td>
                    <td>{pattern.positivePredictedValue.lt70?.toFixed(1)}</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: PieChartColorOrange }}>&lt; 75</td>
                    <td>{pattern.truePositive.lt75}</td>
                    <td>{pattern.positivePredictedValue.lt75?.toFixed(1)}</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: PieChartColorYellow }}>&lt; 80</td>
                    <td>{pattern.truePositive.lt80}</td>
                    <td>{pattern.positivePredictedValue.lt80?.toFixed(1)}</td>
                </tr>
                <tr>
                    <td style={{ backgroundColor: PieChartColorGreen }}>&gt; 80</td>
                    <td>{pattern.truePositive.gt80}</td>
                    <td>{pattern.positivePredictedValue.gt80?.toFixed(1)}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default PatternCharts
